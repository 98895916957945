.share {
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
}

  .share__label {
    display: flex;
    align-items: center;
    color: var(--color-black);
  }
    
    .share__label svg {
      width: 1.2em;
      height: 1.2em;
      margin-right: .3em;
      color: var(--color-gray-300);
    }

  .share__link {
    width: 50%;
    font-size: .8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: Menlo;
    background-color: var(--color-white);
    color: var(--color-gray-500);
    cursor: pointer;
  }