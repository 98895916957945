#Slowed {
  --spacing-0: 0;
  --spacing-05: .125rem;
  --spacing-1: .25rem;
  --spacing-2: .5rem;
  --spacing-3: .75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 2rem;
  --spacing-8: 2.5rem;
  --spacing-9: 3rem;
  --spacing-10: 4rem;
}