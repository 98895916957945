.slider {
  --slider-height: 30px;
  --slider-thumb-size: 20px;
  --slider-point-size: 4px;
  --slider-track-size: 1px;

  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-7);
}

    .slider--on input[type=range]::-webkit-slider-thumb {
      border-color: var(--color-pink-500) !important;
    }


  .slider input[type=range] {
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    background-color: transparent;
    cursor: none;
  }

  .slider input[type=range]:focus {
    outline: none;
  }
 
  .slider input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--slider-track-size);
    border-radius: var(--slider-track-size);
    transition: .15s background;
    cursor: none;
  }

  .slider input[type=range]::-webkit-slider-thumb {
    position: relative;
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    margin-top: calc((var(--slider-thumb-size) / -2) + (var(--slider-track-size) / 2));
    border-radius: 50%;
    box-shadow: var(--color-gray-200) 0 0 0 1px, var(--color-gray-100) 0 10px 10px -5px;
    transition: box-shadow .15s ease;
    cursor: -webkit-grab;
    -webkit-appearance: none;
    transition: .15s all;
    z-index: 2;
    opacity: 0;
  }

    .slider:hover input[type=range]::-webkit-slider-thumb {
      opacity: 1;
      background: var(--color-white);
    }

  .slider input[type=range]:active::-webkit-slider-thumb {
    transform: scale(.95);
  }

    .slider input[type=range]::-webkit-slider-thumb:hover {
      box-shadow: var(--color-gray-300) 0 0 0 1px, var(--color-gray-100) 0 10px 10px -5px;
    }


  .slider__interactor {
    position: relative;
    flex: 1;
    /* cursor: pointer; */
    height: var(--slider-height);
    cursor: none;
  }

  .slider__points {
    position: absolute;
    pointer-events: none;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .slider__point {
    --slider-margin-correction: calc((var(--slider-thumb-size) / 2) - (var(--slider-point-size) / 2));

    position: relative;
    width: var(--slider-point-size);
    height: var(--slider-point-size);
  }

    .slider__point--hide {
      opacity: 0;
    }

    .slider:hover .slider__point:after {
      transform: scale(1.6);
    }

    .slider:hover .slider__point:not(.slider__point--active):after {
      background-color: var(--color-gray-300);
    }

    .slider__point:after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-gray-200);
      content: "";
      border-radius: 50%; 
      transition: .3s transform;
    }

    .slider__point--active .slider__label {
      opacity: 1;
    }

    .slider__point:first-child {
      margin-left: var(--slider-margin-correction);
    }

    .slider__point:last-child {
      margin-right: var(--slider-margin-correction);
    }

    .slider__point--active:after {
      background-color: var(--color-pink-500);
      transform: scale(2.5);
    }

  .slider__label {
    position: absolute;
    top: 18px;
    font-size: var(--font-size-xsmall);
    width: 60px;
    text-align: center;
    margin-left: -28px;
    opacity: 0;
    font-weight: 500;
    color: var(--color-black);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .slider__ghost {
    width: 2px;
    background-color: var(--color-pink-500);
    position: absolute;
    margin-left: -1px;
    opacity: 0;
    z-index: 1;
    cursor: none;
    pointer-events: none;
    transition: .15s opacity;
  }

  .slider__ghost--active {
    opacity: 1;
  }