.reverb {

}

  .reverb__label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: var(--spacing-5);
    letter-spacing: -.02em;
    color: var(--color-gray-700);
  }

  .reverb__label svg {
    width: 1.4em;
    height: 1.4em;
    margin-right: var(--spacing-1);
  } 

.reverb-items {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--spacing-2);
  padding: 0 var(--spacing-2);
}

  .reverb-item {
    position: relative;
    display: flex;
    color: var(--color-gray-900);
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    padding: var(--spacing-1);
    justify-content: space-around;
  }

  .reverb-item--locked {
    cursor: not-allowed;
  }

  .reverb-item:active {
    top: 1px;
  }

  .reverb-item--light .reverb-item__checkmark {
    color: var(--white);
  }

  .reverb-item--dark .reverb-item__checkmark {
    color: var(--black);
  }

    .reverb-item__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity .2s, transform .3s ease;
      transform: scale(.9);
    }

      .reverb-item__checkmark svg {
        width: .9em;
        height: .9em;
      }

    .reverb-item__bg {
      position: relative;
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 50%;
      border: 1px solid #00000017;
      opacity: .5;
      transition: opacity .2s;
    }

    .reverb-item:not(.reverb-item--locked):before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 10px;
      background-color: var(--color-gray-100);
      opacity: 0;
      transform: scale(.9);
      transition: opacity .2s, transform .3s ease;
      content: "";
    }

    .reverb-item:hover:before {
      opacity: 1;
      transform: scale(1.05);
    }

    .reverb-item:active:before {
      transform: scale(1.1);
    }

    .reverb-item:not(.reverb-item--locked):hover .reverb-item__label {
      transform: scale(1.05);
    }

    .reverb-item:not(.reverb-item--locked):hover .reverb-item__bg {
      opacity: .9;
    }

    .reverb-item__label {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: var(--spacing-2);
      color: var(--color-gray-900);
      transition: transform .2s ease;
      font-size: var(--font-size-xsmall);
    }
      
    .reverb-item__label svg {
      width: 1em;
      height: 1em;
      color: var(--color-gray-300);
      margin-right: .3em;
    }

    .reverb-item--on .reverb-item__bg {
      opacity: 1;
    }

    .reverb-item--on:not(.reverb-item--locked):before {
      opacity: 1;
      transform: scale(1);
    }

    .reverb-item--on .reverb-item__checkmark {
      opacity: 1;
      transform: scale(1);
    }

    .reverb-item--on .reverb-item__label {
      color: var(--color-black);
      font-weight: 500;
    }

    .reverb-item--locked .reverb-item__label {
      color: var(--color-gray-200);
    }

    .reverb-item--locked .reverb-item__bg {
      background-color: transparent !important;
      border: 1px dashed var(--color-gray-300);
    }