.mounting * {
  transition: none !important;
}

.mounting *:before {
  transition: none !important;
}

.mounting *:after {
  transition: none !important;
}