.input {
  border: 0;
  padding: 0;
}

  .input:active {
    outline: 0;
  }

  .input:focus {
    outline: 0;
  }