.effect {

}

  .effect__label {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-5);
    font-weight: 500;
    letter-spacing: -.02em;
    color: var(--color-gray-700);
  }

  .effect__label svg {
    width: 1.4em;
    height: 1.4em;
    margin-right: var(--spacing-1);
  } 

.effect-items {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--spacing-1) * -1);
  padding: 0 var(--spacing-2);
}

  .effect-items .effect-item {
    margin: var(--spacing-05);
  }

.effect-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: 6px;
  background-color: transparent;
  font-weight: 400;
  color: var(--color-gray-900);
  border-radius: 10px;
}

  .effect-item--locked {
    cursor: not-allowed;
  }

  .effect-item:not(.effect-item--locked):before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
    background-color: var(--color-gray-100);
    opacity: 0;
    transform: scale(.9);
    transition: opacity .2s, transform .3s ease;
    content: "";
  }

  .effect-item:hover:before {
    opacity: 1;
    transform: scale(1.05);
  }

  .effect-item:active:before {
    transform: scale(1.1);
  }

  .effect-item .effect-item__content > svg {
    width: 1em;
    height: 1em;
    margin-right: var(--spacing-1);
    color: var(--color-gray-300);
  }

  .effect-item:active {
    top: 1px;
  }

  .effect-item--active {
    color: var(--color-black);
    font-weight: 500;
  }

    .effect-item--active:not(.effect-item--locked):before {
      opacity: 1;
      transform: scale(1);
    }

  .effect-item--locked {
    color: var(--color-gray-200);
  }

  .effect-item__content {
    position: relative;
    display: flex;
    align-items: center;
  }