.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-align: center;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: .5rem;
  padding: var(--spacing-1) var(--spacing-2);
  color: var(--color-gray-900);
  background-color: var(--color-gray-100);
  font-size: var(--font-size-xxsmall);
  height: 18px;
}

  .button:disabled {
    cursor: not-allowed;
  }