.slow {
  /* Compensate absolute label */
  padding-bottom: 16px; 
}

  .slow > *:not(:last-child) {
    margin-right: var(--spacing-2);
  }

  .slow__label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: var(--spacing-5);
    color: var(--color-gray-700);
    letter-spacing: -.02em;
  }

    .slow__label svg {
      width: 1.4em;
      height: 1.4em;
      margin-right: var(--spacing-1);
    } 

  .slow__handler {
    flex: 1;
  }

  .slow__switcher {
    display: flex;
  }