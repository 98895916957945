.actions {
  display: flex;
  flex-direction: column;
  color: var(--color-gray-400);
  font-size: var(--font-size-xxsmall);
  gap: var(--spacing-2);
}

  .actions__list {
    display: flex;
    row-gap: var(--spacing-2);
    column-gap: var(--spacing-3);
    flex-wrap: wrap;
    justify-content: center;
  }

  .actions__status {
    font-weight: 600;
    text-align: center;
  }

  .actions a {
    text-decoration: none;
    color: var(--color-gray-400);
  }

  .actions a:hover {
    color: var(--color-gray-800);
  }