.switcher {
  position: relative;
  width: 40px;
  height: 18px;
  background-color: var(--color-gray-200);
  appearance: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background .15s;
}

  .switcher:before {
    position: absolute; 
    left: 0;
    top: 0;
    width: calc(55% - 4px);
    height: calc(100% - 4px);
    border-radius: 6px;
    background-color: var(--white);
    content: "";
    margin: 2px;
    transition: left .15s ease-in-out;
  }

  .switcher:active {
    background-color: var(--color-pink-300);
  }

  .switcher:checked {
    background-color: var(--color-pink-500);
  }

  .switcher:checked:active {
    background-color: var(--color-pink-600);
  }

  .switcher:checked:before {
    left: 18px;
  }