.other-control {
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
}

  .other-control__label {
    display: flex;
    align-items: center;
    color: var(--color-gray-700);
    letter-spacing: -.02em;
    font-weight: 500;
  }
    
    .other-control__label svg {
      width: 1.4em;
      height: 1.4em;
      margin-right: var(--spacing-1);
    }

  .other-control--locked .other-control__label {
    color: var(--color-gray-300);
  }