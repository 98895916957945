* {
  box-sizing: inherit;
}

*::before {
  box-sizing: inherit;
}

*::after {
  box-sizing: inherit;
}

button, 
input, 
optgroup, 
select, 
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}