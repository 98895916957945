.content {
  position: relative;
  display: grid;
  gap: var(--spacing-6);
  transform: scale(1);
  transition: transform .3s ease-out;
}

  .content--disabled {
    transform: scale(0.98);
  }

  .content__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    background-color: var(--color-white);
    opacity: .6;
    z-index: 999;
  }

.subcontent {
  display: grid;
  gap: var(--spacing-2);
}
