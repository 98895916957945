.header {
  position: relative;
  margin-bottom: var(--spacing-4);
}

  .header__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__title {
    
  }

    .header__title svg {
      width: auto;
      height: 19px;
    }

  .header__left_button {
    position: absolute;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .header__right_button {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .header__right_button span {
      margin-right: .5rem;
      font-weight: 500;
      font-size: var(--font-size-xsmall);
    }

  .header__bar {
    width: 100%;
    margin: var(--spacing-2) auto var(--spacing-1) auto;
    height: 1px;
    border-radius: 1px;
    background-color: var(--color-gray-100);
  }